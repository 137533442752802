import React, { useRef, useState, useEffect } from "react";
import "../styles/Camera.css";
import Spinner from "./Spinner";

function Camera({
  onPhotoTaken,
  recordStreams,
  setRecordStreams,
  isUploading,
  imageUploads,
}) {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);

  useEffect(() => {
    getVideo();
    return () => {
      closeCamera();
    };
  }, []);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: { exact: "environment" },
          width: { ideal: 2048 }, // Request HD resolution (1920x1080)
          height: { ideal: 1536 },
        },
      })
      // .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
        let helpArr = recordStreams;
        helpArr.push(stream);
        setRecordStreams(helpArr);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
      });
  };

  const takePhoto = () => {
    if (isUploading) {
      return;
    }

    let video = videoRef.current;
    let photo = photoRef.current;

    // Get the video dimensions to set the canvas size dynamically
    const width = video.videoWidth;
    const height = video.videoHeight;

    photo.width = width;
    photo.height = height;

    let context = photo.getContext("2d");

    // Draw the image directly without mirroring
    context.drawImage(video, 0, 0, width, height);

    const dataUrl = photo.toDataURL("image/png");
    setHasPhoto(true);

    // Convert dataUrl to Blob
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "photo.png", { type: "image/png" });

        let imgObject = {
          localURL: URL.createObjectURL(file),
          files: file,
          filetype: file.type,
        };

        // Pass the object to the parent component
        onPhotoTaken(imgObject);

        // getVideo(); // Restart the video stream after capturing the photo
      })
      .catch((err) => {
        console.error("Error converting dataUrl to Blob: ", err);
      });
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
    }
  };

  const autoAdjustZoom = (videoElement) => {
    // Check if the video element and its video tracks exist
    if (!videoElement || !videoElement.srcObject) {
      console.error("Video element or source object is missing.");
      return;
    }

    const videoTrack = videoElement.srcObject.getVideoTracks()[0];
    const capabilities = videoTrack.getCapabilities();
    const settings = videoTrack.getSettings();

    // Check if the zoom property is supported
    if (capabilities.zoom) {
      console.log("Camera supports zoom.");

      const minZoom = capabilities.zoom.min;
      const maxZoom = capabilities.zoom.max;
      const currentZoom = settings.zoom || minZoom;

      // Calculate a new zoom value to apply
      const desiredZoom = Math.min(currentZoom + 1, maxZoom); // Adjust zoom incrementally or to a specific value

      console.log(`Current zoom: ${currentZoom}, Desired zoom: ${desiredZoom}`);

      // Apply the zoom setting
      videoTrack
        .applyConstraints({
          advanced: [{ zoom: desiredZoom }],
        })
        .then(() => {
          console.log(`Zoom successfully set to ${desiredZoom}`);
        })
        .catch((error) => {
          console.error("Error applying zoom:", error);
        });
    } else {
      console.warn("Zoom is not supported by this camera.");
    }
  };

  return (
    <div className={!cameraActive ? "d-none" : "camera"}>
      <div className="camera-container">
        <video ref={videoRef} playsInline muted></video>
        {!cameraActive && (
          <div className="no-camera-access" onClick={getVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      <div className="camera_div_bottom">
        <div
          className={
            isUploading
              ? "camera_div_bottom_takePhoto_disabled"
              : "camera_div_bottom_takePhoto"
          }
          onClick={takePhoto}>
          <div
            className={
              isUploading
                ? "camera_div_bottom_takePhoto_inner_disabled"
                : "camera_div_bottom_takePhoto_inner"
            }>
            {imageUploads.length > 0 && imageUploads.length}
          </div>
        </div>
      </div>

      {
        <div className={"result " + (hasPhoto ? "hasPhoto" : " d-none")}>
          <canvas
            ref={photoRef}
            style={{
              objectFit: "cover",
              width: "30px",
              height: "30px",
              zIndex: "-9999",
            }}></canvas>
          <div className="blackbox"></div>
        </div>
      }
    </div>
  );
}

export default Camera;
