import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Content from "./screens/Dokumente";
import Letsfluence from "./screens/Letsfluence";
import Dokumente from "./screens/Dokumente";

const developerMode = true;

export default function App() {
  let apiURL = "https://www.cloudaccounting.minawal.de";
  if (developerMode) {
    apiURL = "https://www.cloudaccounting.minawal.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [activeProductID, setActiveProductID] = useState(9999999);
  const [searchStringDokumente, setSearchStringDokumente] = useState("");
  const [searchStringLetsfluence, setSearchStringLetsfluence] = useState("");
  const [filteredDokumente, setFilteredDokumente] = useState([]);
  const [filteredLetsfluence, setFilteredLetsfluence] = useState([]);

  const [uploading, setIsUploading] = useState(false);

  const isMobile = useScreenType();

  const [reloadContent, setReloadContent] = useState(false);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken] // Additional dependencies, if needed
  );

  const {
    data: dokumente,
    loading: loadingDokumente,
    error: errorDokumente,
  } = useFetch(
    `${apiURL}/api/dokumente/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadContent] // Additional dependencies, if needed
  );

  const {
    data: letsfluence,
    loading: loadingLetsfluence,
    error: errorLetsfluence,
  } = useFetch(
    `${apiURL}/api/letsfluence/noAuthOnlyGET.php`,
    authToken,
    "GET",
    null,
    [authToken, reloadContent] // Additional dependencies, if needed
  );

  useEffect(() => {
    let helpArr = [...dokumente];
    let helpArr2 = [];
    if (searchStringDokumente.length > 2) {
      helpArr.forEach((obj) => {
        // Check if the 'titel' key includes the search string (case-insensitive)
        if (
          obj.titel.toLowerCase().includes(searchStringDokumente.toLowerCase())
        ) {
          helpArr2.push(obj); // If yes, push the object to helpArr
        }
      });
      setFilteredDokumente(sortArrayByDate(helpArr2));
    } else {
      setFilteredDokumente(sortArrayByDate(helpArr));
    }
  }, [dokumente, searchStringDokumente]);

  useEffect(() => {
    let helpArr = [...letsfluence];
    let helpArr2 = [];
    if (searchStringLetsfluence.length > 2) {
      helpArr.forEach((obj) => {
        // Check if the 'titel' key includes the search string (case-insensitive)
        if (
          obj["Beguenstigter_Zahlungspflichtiger"]
            .toLowerCase()
            .includes(searchStringLetsfluence.toLowerCase())
        ) {
          helpArr2.push(obj); // If yes, push the object to helpArr
        }
      });
      setFilteredLetsfluence(sortArrayByBuchungstag(helpArr2));
    } else {
      setFilteredLetsfluence(sortArrayByBuchungstag(helpArr));
    }
  }, [letsfluence, searchStringLetsfluence]);

  const monthMap = {
    Januar: 0,
    Februar: 1,
    März: 2,
    April: 3,
    Mai: 4,
    Juni: 5,
    Juli: 6,
    August: 7,
    September: 8,
    Oktober: 9,
    November: 10,
    Dezember: 11,
  };

  // Helper function to convert the German month to its corresponding index
  function getMonthIndex(monthName) {
    return monthMap[monthName] ?? -1; // Default to -1 if the month name isn't found
  }

  function sortArrayByDate(array) {
    return array.sort((a, b) => {
      // Convert each object to a Date object
      const dateA = new Date(
        parseInt(a.dateYear, 10),
        getMonthIndex(a.dateMonth),
        parseInt(a.dateDay, 10)
      );
      const dateB = new Date(
        parseInt(b.dateYear, 10),
        getMonthIndex(b.dateMonth),
        parseInt(b.dateDay, 10)
      );

      // Sort by date in ascending order (earliest date first)
      return dateB - dateA;
    });
  }

  function sortArrayByBuchungstag(array) {
    return array.sort((a, b) => {
      // Split the Buchungstag date string into day, month, and year components
      const [dayA, monthA, yearA] = a.Buchungstag.split(".").map(Number);
      const [dayB, monthB, yearB] = b.Buchungstag.split(".").map(Number);

      // Convert the date parts into Date objects
      const dateA = new Date(2000 + yearA, monthA - 1, dayA); // Add 2000 to year for 21st century
      const dateB = new Date(2000 + yearB, monthB - 1, dayB);

      // Sort by date in ascending order (earliest date first)
      return dateB - dateA;
    });
  }

  const ereignisse = [
    "Nebenkostenabrechnung 2023 Walddörferstraße",
    "Nebenkostenabrechnung 2024 Walddörferstraße",
    "Nebenkostenabrechnung 2023 Fasanenweg 16",
    "Nebenkostenabrechnung 2024 Fasanenweg 16",
    "Steuer Privat",
  ];

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Dokumente
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={filteredDokumente}
                  reloadProducts={reloadContent}
                  setReloadProducts={setReloadContent}
                  searchString={searchStringDokumente}
                  setSearchString={setSearchStringDokumente}
                />
              }
            />
            <Route
              path="/letsfluence"
              element={
                <Letsfluence
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={filteredLetsfluence}
                  dokumente={filteredDokumente}
                  reloadProducts={reloadContent}
                  setReloadProducts={setReloadContent}
                  searchString={searchStringLetsfluence}
                  setSearchString={setSearchStringLetsfluence}
                />
              }
            />
            <Route
              path="/masih"
              element={
                <Letsfluence
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  setActiveProductID={setActiveProductID}
                  activeProductID={activeProductID}
                  products={filteredLetsfluence}
                  dokumente={filteredDokumente}
                  reloadProducts={reloadContent}
                  setReloadProducts={setReloadContent}
                  searchString={searchStringLetsfluence}
                  setSearchString={setSearchStringLetsfluence}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
