import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdCheckCircle,
  MdDelete,
  MdImage,
  MdMenu,
  MdNotInterested,
} from "react-icons/md";

import "../styles/Products.css";
import "../styles/Sidebar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";

export default function Letsfluence({
  isMobile,
  products,
  apiURL,
  reloadProducts,
  setReloadProducts,
  page,
  variationProducts,
  setVariationProducts,
  searchString,
  setSearchString,
  dokumente,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  const [activeID, setActiveID] = useState("");
  const [activeDokument, setActiveDokument] = useState({});
  const [file, setFile] = useState(null);
  const [balance, setBalance] = useState(0);

  const [title, setTitle] = useState("");
  const [bilder, setBilder] = useState([]);
  const [zugeordnet_doc_id, setZugeordnet_doc_id] = useState("");

  const [dokumentenart, setDokumentenart] = useState("");
  const [note, setNote] = useState("");
  const [dateDay, setDateDay] = useState("");
  const [dateMonth, setDateMonth] = useState("");
  const [dateYear, setDateYear] = useState("");
  const [betreff, setBetreff] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [mediaImageURL, setMediaImageURL] = useState("");
  const [imageUploads, setImageUploads] = useState([]);

  const setActiveElement = (element) => {
    setActiveID(element.id);
    setDateYear(element.dateYear);
    setZugeordnet_doc_id(element.zugeordnet_doc_id);

    let newArray = [];
    JSON.parse(element.bilder).map((pic) => newArray.push(pic.url));
    setBilder(newArray);
    setMediaImageURL(JSON.parse(element.bilder)[0].url);
    setImageUploads([]);
  };

  function convertYear(dateString) {
    // Split the date string by the '.' delimiter
    const [day, month, year] = dateString.split(".");

    // Convert the year from string to integer
    let yearInt = parseInt(year, 10);

    // Determine if the year belongs to the 1900s or 2000s
    if (yearInt <= 50) {
      // You can adjust this threshold as needed
      yearInt += 2000;
    } else {
      yearInt += 1900;
    }

    // Return the year in yyyy format
    return yearInt;
  }

  useEffect(() => {
    // filter dokumente based on the year
  }, [dateYear]);

  useEffect(() => {
    if (!isEmpty(products) && !isEmpty(activeID)) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].id == activeID) {
          let newArray = [];
          JSON.parse(products[i].bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
        }
      }
    }
  }, [products]);

  useEffect(() => {
    if (products && products.length > 0) {
      let count = 0;
      if (searchString.length == 0) {
        count = count - 3365.35;
        count = count + 18356.98;
      }

      for (let i = 0; i < products.length; i++) {
        // Replace thousands separators and decimal separator
        let numberStr = products[i].Betrag.replace(/\./g, "") // Remove thousands separators
          .replace(",", "."); // Replace decimal comma with dot

        // Convert to a floating-point number
        const parsedAmount = parseFloat(numberStr);

        // Check if the parsed amount is a valid number
        if (!isNaN(parsedAmount)) {
          count += parsedAmount;
        } else {
          console.warn(
            `Invalid number encountered at index ${i}: ${numberStr}`
          );
        }
      }

      // Set the balance formatted to two decimal places
      setBalance(count.toFixed(2));
    }
  }, [products]);

  const handleUpload = async (imgURL) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));
    if (!isEmpty(imgURL)) {
      pics.push({ url: imgURL });
    }
    const resa = await fetch(
      `${apiURL}/api/letsfluence/index.php?id=` + activeID,
      {
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          zugeordnet_doc_id: zugeordnet_doc_id,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const addNewProduct = async () => {
    return;
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const resa = await fetch(`${apiURL}/api/letsfluence/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        titel: "New Document",
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const duplicateProduct = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let pics = [];
    bilder.map((img) => pics.push({ url: img }));

    const resa = await fetch(`${apiURL}/api/letsfluence/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({}),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = (value) => {
    setIsLoading(false);
    setReloadProducts(!reloadProducts);
    if (isMobile) {
      setActiveID("");
    }

    if (value != "") {
      setImageUploads([]);
      setFile(null);
    }
  };

  useEffect(() => {
    var productList = document.getElementById("productList");
    var productDetail = document.getElementById("productDetail");

    if (productList != null && productDetail != null) {
      if (isMobile && activeID == "") {
        productDetail.classList.add("d-none");
        productList.classList.remove("d-none");
      } else if (isMobile) {
        productDetail.classList.remove("d-none");
        productList.classList.add("d-none");
      } else {
        productList.classList.remove("d-none");
        productDetail.classList.remove("d-none");
      }
    }
  }, [isMobile, activeID]);

  const dokumentenarten = [
    "Rechnungen / Rechnungsbescheide",
    "Vertrag / Rechtliches / Urkunden / Bescheide",
    "Zugangsdaten / Anschreiben",
  ];

  const tage = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const monate = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const jahre = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];

  function getTodayDay() {
    let today = new Date();
    let day = today.getDate();
    return day.toString();
  }

  function getCurrentMonthInGerman() {
    const monthsInGerman = [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ];

    let today = new Date();
    let monthIndex = today.getMonth(); // getMonth() returns month from 0-11
    return monthsInGerman[monthIndex];
  }

  function getCurrentYear() {
    let today = new Date();
    let year = today.getFullYear();
    return year.toString();
  }

  const insertLocalImage = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype = "image";
      let newArray = [];
      imageUploads.map((imgObj) => newArray.push(imgObj));
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setImageUploads(newArray);
      setMediaImageURL(URL.createObjectURL(file));
    } else {
      return;
    }

    return;
  };

  const handleUploadForImage = async () => {
    setIsLoading(true);

    const files = document.getElementById("imgInp").files;
    const formData = new FormData();
    if (!isEmpty(imageUploads)) {
      setMediaImageURL(imageUploads[0].localURL);
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          //  Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => handleUpload(value));
    } else {
      handleUpload();
    }
  };

  const removeImage = () => {
    let newArray = [];
    for (let i = 0; i < bilder.length; i++) {
      if (bilder[i] != mediaImageURL) {
        newArray.push(bilder[i]);
      }
    }
    setBilder(newArray);
  };

  const moveImage = (direction) => {
    let searchString = mediaImageURL;
    const array = [];
    bilder.map((img) => array.push(img));
    const index = array.indexOf(searchString);
    // Check if the searchString is present in the array
    if (index === -1) {
      console.error("String not found in array");
      return array;
    }

    // Check if the searchString is not at the start or end of the array
    if (
      (index > 0 && direction === -1) ||
      (index < array.length - 1 && direction === 1)
    ) {
      // Swap the elements
      const temp = array[index];
      array[index] = array[index + direction];
      array[index + direction] = temp;

      setBilder(array);
    } else {
      console.error("Cannot move element beyond array bounds");
      return array;
    }
  };

  const resetImageUpload = () => {
    setImageUploads([]);
    setMediaImageURL(bilder[0]);
  };

  const uploadHaspaCSV = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    // Create a FormData object
    const formData = new FormData();
    const files = document.getElementById("inputcsv").files;
    formData.append("file", files[0]);

    // Send the CSV file to the server using fetch

    const response = await fetch(`${apiURL}/api/letsfluence/uploadcsv.php`, {
      method: "POST", // Assuming PUT method, adjust if necessary
      body: formData,
    });

    await response.text().then((value) => uploadEnds(value));
  };

  const clickOnInput = () => {
    let el = document.getElementById("inputcsv");
    el.click();
  };

  useEffect(() => {
    if (!isEmpty(file)) {
      uploadHaspaCSV();
    }
  }, [file]);

  useEffect(() => {
    if (!isEmpty(zugeordnet_doc_id)) {
      dokumente.map((doc) => {
        if (doc.id == zugeordnet_doc_id) {
          let newArray = [];
          JSON.parse(doc.bilder).map((pic) => newArray.push(pic.url));
          setBilder(newArray);
          setMediaImageURL(JSON.parse(doc.bilder)[0].url);
        }
      });
    }
  }, [zugeordnet_doc_id]);

  return (
    <div className="page-divider">
      <div className="sidebar">
        <div className="sidebar_top">
          {!isMobile && (
            <div className="" style={{ paddingLeft: "14px" }}>
              Buchhaltung
            </div>
          )}

          {!isMobile ? (
            <>
              {location.pathname.substring(0, 6) == "/" ? (
                <div className="sidebar_top_navpoint_active">Dokumente</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/")}>
                  Dokumente
                </div>
              )}
              {location.pathname.substring(0, 10) == "/ereignisse" ? (
                <div className="sidebar_top_navpoint_active">Ereignisse</div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/ereignisse")}>
                  Ereignisse
                </div>
              )}
              {location.pathname.substring(0, 12) == "/letsfluence" ? (
                <div className="sidebar_top_navpoint_active">
                  Kto Letsfluence
                </div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/letsfluence")}>
                  Kto Letsfluence
                </div>
              )}
              {location.pathname.substring(0, 6) == "/masih" ? (
                <div className="sidebar_top_navpoint_active">
                  Kto Masih Privat
                </div>
              ) : (
                <div
                  className="sidebar_top_navpoint"
                  onClick={() => navigate("/masih")}>
                  Kto Masih Privat
                </div>
              )}
            </>
          ) : (
            <>
              {activeID == "" && (
                <div className="topnav">
                  <div className="sidebar_top">
                    {location.pathname.substring(0, 6) == "/" ? (
                      <div className="sidebar_top_navpoint_active">
                        Letsfluence
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/")}>
                        Letsfluence
                      </div>
                    )}
                    {location.pathname.substring(0, 7) == "/grillchef" ? (
                      <div className="sidebar_top_navpoint_active">
                        {" "}
                        Grillchef
                      </div>
                    ) : (
                      <div
                        className="sidebar_top_navpoint"
                        onClick={() => navigate("/grillchef")}>
                        Grillchef
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="sidebar_bottom"></div>
      </div>
      <div className="products">
        <div className="products_left" id="productList">
          <div className="products_list">
            <div className="products_filter">
              <input
                type="text"
                placeholder="Titel filtern"
                className="product_filter_search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <select className="product_filter_select">
                <option value="">Ab</option>
              </select>
              <select className="product_filter_select">
                <option value="">Bis</option>
              </select>
            </div>
            <div className="products_addNew">
              <div
                className="products_addNew_button"
                onClick={() => clickOnInput()}>
                {!isEmpty(file) ? <div>...isLoading</div> : "Upload CSV"}
              </div>
              <input
                id="inputcsv"
                type="file"
                accept=".csv"
                className="d-none"
                onChange={(e) => setFile(e.target.value)}
              />
              <div
                className="products_addNew_button"
                onClick={() => addNewProduct()}>
                + Add New Buchung
              </div>
              <div className="products_addNew_button">{balance + " €"}</div>
            </div>

            {products.map((product) => (
              <div key={product.id}>
                {product.id == activeID ? (
                  <>
                    <div className="products_list_element_active">
                      <div className="products_list_element_col">
                        {product.Buchungstag}
                      </div>
                      <div className="products_list_element_col">
                        {isEmpty(product.zugeordnet_doc_id) ? (
                          <NoDocIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </div>
                      <div className="products_list_element_col">
                        {product["Beguenstigter_Zahlungspflichtiger"] + "   | "}
                      </div>
                      <div className="products_list_element_col">
                        {product.Betrag}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="products_list_element"
                      onClick={() => setActiveElement(product)}>
                      <div className="products_list_element_col">
                        {product.Buchungstag}
                      </div>
                      <div className="products_list_element_col">
                        {isEmpty(product.zugeordnet_doc_id) ? (
                          <NoDocIcon />
                        ) : (
                          <CheckIcon />
                        )}
                      </div>
                      <div className="products_list_element_col">
                        {product["Beguenstigter_Zahlungspflichtiger"] + "   | "}
                      </div>
                      <div className="products_list_element_col">
                        {product.Betrag}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="products_right" id="productDetail">
          {activeID != "" && (
            <>
              {isMobile ? (
                <div className="topnav">
                  <div className="" onClick={() => setActiveID("")}>
                    <LeftIcon /> Zurück
                  </div>
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              ) : (
                <div className="topnav">
                  {isLoading ? (
                    <div className="savebtn_loading">...Lädt</div>
                  ) : (
                    <div
                      className="savebtn"
                      onClick={() => handleUploadForImage()}>
                      Speichern
                    </div>
                  )}
                </div>
              )}{" "}
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                id={"imgInp"}
                name="files[]"
                className="createService_inputBtn d-none"
                onChange={(e) => insertLocalImage(e)}
              />
              <div className=""> Datum des Dokuments Monat</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDateMonth(e.target.value)}
                  className="inputrow_input">
                  {monate.map((tag) => (
                    <>
                      {dateMonth == tag ? (
                        <option value={tag} selected>
                          {tag}
                        </option>
                      ) : (
                        <option value={tag}>{tag}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Datum des Dokuments Jahr</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setDateYear(e.target.value)}
                  className="inputrow_input">
                  {jahre.map((tag) => (
                    <>
                      {dateYear == tag ? (
                        <option value={tag} selected>
                          {tag}
                        </option>
                      ) : (
                        <option value={tag}>{tag}</option>
                      )}
                      +
                    </>
                  ))}
                </select>
              </div>
              <div className=""> Zueordnetes Dokument</div>
              <div className="inputrow">
                <select
                  onChange={(e) => setZugeordnet_doc_id(e.target.value)}
                  className="inputrow_input">
                  {zugeordnet_doc_id == "" ? (
                    <option selected>Keine</option>
                  ) : (
                    <option value="">Keine</option>
                  )}
                  {dokumente.map((dokument) => (
                    <>
                      {zugeordnet_doc_id == dokument.id && (
                        <option value={dokument.id} selected>
                          {dokument.dateDay +
                            "." +
                            dokument.dateMonth +
                            " " +
                            dokument.titel}
                        </option>
                      )}

                      <option value={dokument.id}>
                        {dokument.dateDay +
                          "." +
                          dokument.dateMonth +
                          " " +
                          dokument.titel}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              {!isEmpty(imageUploads) ? (
                <div className="">
                  <img
                    className="createService_inputBtn_img"
                    style={{ width: "calc(100% - 24px)" }}
                    src={mediaImageURL}
                  />
                </div>
              ) : (
                <>
                  <img
                    className="createService_inputBtn_img"
                    style={{ width: "calc(100% - 24px)" }}
                    src={mediaImageURL}
                  />
                </>
              )}
              <div className="creator_previewimages">
                {bilder.map((creatorimg) => (
                  <>
                    <div
                      className="creator_previewimages_imgbox"
                      onClick={() => setMediaImageURL(creatorimg)}>
                      <img
                        src={creatorimg}
                        className={
                          mediaImageURL == creatorimg
                            ? "creator_previewimages_imgbox_img_active"
                            : "creator_previewimages_imgbox_img"
                        }
                      />
                      {mediaImageURL == creatorimg && bilder.length > 1 && (
                        <div className="creator_previewimages_imgbox_nav">
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => removeImage()}>
                            <DeleteIcon />
                          </div>
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => moveImage(-1)}>
                            <LeftIcon />
                          </div>
                          <div
                            className="creator_previewimages_imgbox_nav_btn"
                            onClick={() => moveImage(1)}>
                            <RightIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
                {imageUploads.map((creatorimg) => (
                  <>
                    <div
                      className="creator_previewimages_imgbox"
                      onClick={() => setMediaImageURL(creatorimg.localURL)}>
                      <img
                        className={
                          mediaImageURL == creatorimg.localURL
                            ? "creator_previewimages_imgbox_img_active"
                            : "creator_previewimages_imgbox_img"
                        }
                        src={creatorimg.localURL}
                      />
                      <div className="creator_previewimages_imgbox_nav">
                        <div className="createService_inputBtn_cancelBtn">
                          <CancelIcon onClick={() => resetImageUpload()} />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 33px;
  color: #575757;
`;

const CheckIcon = styled(MdCheckCircle)`
  font-size: 33px;
  color: #575757;
`;

const DeleteIcon = styled(MdDelete)`
  font-size: 33px;
  color: #575757;
`;

const ImageIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const NoDocIcon = styled(MdNotInterested)`
  font-size: 33px;
  color: #575757;
`;

const DocIcon = styled(MdImage)`
  font-size: 33px;
  color: #575757;
`;

const LeftIcon = styled(MdArrowBackIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;

const RightIcon = styled(MdArrowForwardIos)`
  font-size: 22px;
  color: #575757;
  margin-top: -1px;
  margin-left: 10px;
`;
